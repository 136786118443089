@tailwind base;
@tailwind components;
@tailwind utilities;

/* For the support of emojis of county flags */
@font-face {
	font-family: NotoColorEmojiLimited;
	unicode-range: U+1F1E6-1F1FF;
	src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

@layer components {
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}

	/* text responsive */

	.text-base-responsive {
		@apply text-xs lg:text-sm 2xl:text-base;
	}
	.text-sm-responsive {
		@apply text-xs lg:text-xs 2xl:text-sm;
	}
	.text-lg-responsive {
		@apply text-sm lg:text-base 2xl:text-lg;
	}
	.text-3xl-responsive {
		@apply text-lg lg:text-2xl 2xl:text-3xl;
	}
	.header-txt {
		@apply font-semibold text-lg-responsive;
	}
	.sub-header-txt {
		@apply font-normal text-sm-responsive text-textGray;
	}

	.ring-none {
		--tw-ring-shadow: 0 0 #000 !important;
	}

	/* scrollbar */

	.custom-scrollbar::-webkit-scrollbar {
		@apply w-2 h-2;
	}
	.custom-scrollbar::-webkit-scrollbar-track {
		@apply bg-[#f1f1f1];
	}
	.custom-scrollbar::-webkit-scrollbar-thumb {
		@apply bg-[#667085] rounded-lg;
	}

	/* layout */

	.layout-container {
		@apply mx-auto max-w-[1770px] px-5 lg:px-10;
	}

	/* Range slider */

	.slider-container .rangeslider__fill {
		@apply bg-[#19B454]  !important;
	}

	.slider-container .rangeslider__handle {
		@apply size-5 bg-white border-2 border-primary rounded-full shadow-none !important;
	}

	.slider-container .rangeslider__handle .rangeslider__handle-tooltip {
		@apply text-primary text-sm-responsive font-semibold bg-transparent p-0 top-[19px] !important;
	}

	.slider-container .rangeslider__handle .rangeslider__handle-tooltip::after {
		@apply content-none !important;
	}

	.slider-container .rangeslider__handle::after {
		@apply h-0 w-0 shadow-none !important;
	}

	.react-international-phone-input {
		height: 42px;
		width: 100%;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
	.react-international-phone-country-selector-button {
		width: 60px;
		height: 42px;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	.custom-profile {
		img {
			object-fit: cover;
		}
	}
	.bg-green-custom-gradient {
		background: linear-gradient(180deg, #b1ffbf 0%, #2dc265 100%);
	}
	.bg-yellow-custom-gradient {
		background: linear-gradient(180deg, #ffee93 0%, #ffe142 100%);
	}
	.bg-red-custom-gradient {
		background: linear-gradient(180deg, #ff8e8e 0%, #ff5555 100%);
	}
	.scrollbar-none {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.title-text {
		@apply text-textBlack font-semibold text-lg-responsive mb-2 lg:mb-2 2xl:mb-4;
	}
	.input-label {
		@apply mb-[3px] lg:mb-[3px] 2xl:mb-[6px] flex text-sm-responsive font-medium text-[#344054] dark:text-gray-300;
	}
	.input-select {
		@apply inline-flex w-full items-center justify-between rounded-lg border bg-white px-[10px] py-2 text-center text-sm-responsive font-medium;
	}

	.stroke-white-svg {
		svg {
			@apply stroke-white;
		}
	}

	.grid-view-card {
		@apply flex-col w-full md:w-[48%] xl:w-[32%] 2xl:w-[24%] rounded-xl border bg-[#F5F9FD] px-3 py-3 shadow-md select-none;
	}

	.disabled {
		@apply opacity-40 pointer-events-none;
	}

	.form-layout {
		@apply px-4 lg:px-16 py-5 border-x rounded-b-lg border-b;
	}
	.date-input input {
		@apply p-2 pl-10 bg-transparent text-sm-responsive;
	}
	.date-input button {
		@apply block cursor-pointer rounded-lg border-0 text-center font-semibold leading-8 text-xs;
	}
	.date-input input::placeholder {
		@apply text-sm-responsive text-themeGray-text;
	}

	.tr-left-border:hover td:first-child {
		border-left: 4px solid #4b88ef;
	}

	.tr-left-border > td {
		border-left: 4px solid transparent;
	}

	/* date range picker */
	.date-range-picker .rdrDefinedRangesWrapper {
		width: 106px;
	}
}
@layer components {
	.color-palate-1 {
		--color: #0057b7;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}

			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-2 {
		--color: #1d9533;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-3 {
		--color: #ed3833;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff54;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-4 {
		--color: #f3b743;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-5 {
		--color: #655091;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-6 {
		--color: #a44062;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-7 {
		--color: #ee79c3;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-8 {
		--color: #337e7e;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-9 {
		--color: #7f7f7f;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}

	.color-palate-10 {
		--color: #3a4b90;
		&.with-border {
			border-color: var(--color);
		}
		&.with-bg {
			background-color: var(--color);
			color: white;
			.btn {
				border: 1px solid white;
			}
			.avg_ops {
				background-color: #ffffff1a;
			}
			.avg {
				color: black;
			}
		}
		.nav {
			background-color: var(--color);
			border-radius: 8px 8px 0px 0px;
			.title {
				color: white;
			}
			.count {
				background-color: #e8f4ed;
			}
		}
	}
}
